import React,{useRef,useState,useEffect} from "react";
import { useNavigate , Link } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import {Watch} from "react-loader-spinner";
let apiServices = new ApiService();
function Header(){
    const [PartnerData , setPartnerData] = useState({});
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    useEffect(() => {
        getpartnerdata();
        didMountRef.current = false;
    }, []);
    const getpartnerdata = (type) =>{
            const dataString = {
        }
        setSpinnerLoading(true);
        apiServices.getpartnerdata(dataString).then(res => {
            if(res.data.status == "success"){
                setPartnerData(res.data.data);
                setSpinnerLoading(false);
            }
            else if(res.data.status == "error" && res.data.message == "Session expired"){
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")           
            }
            else{
                setSpinnerLoading(false);
                // setErrorMessage(res.data.message)
            }
        }); 
    }
    const logout = () => {
        const dataString = {
        }
        if (window.confirm("Are You Sure You Want To Logout ?") == true) {
            apiServices.partnerlogoutPostRequest(dataString).then(res => {
              if (res.data.status == "success") {
                localStorage.removeItem('AUTH_TOKEN');
                navigate("/");
              }
            })
          }
        }
    return(
        <>
        <header className="header">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-lg-2 col-6" >
                     <Link to ="/goto"> <img src="/assests/img/logo.png" className="logo"></img></Link>  
                    </div>
                    <div className="col-lg-10 col-6">
                        <div className="d-flex  profile-box">
                            <div className="flex-shrink-0  d-none d-md-block">
                                <h6 className="mb-0">{PartnerData.partner_name}</h6>
                                <p className="tx-12 mb-0">{PartnerData.partner_unique_id}</p>
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <button onClick={logout} className="btn btn-primary btn-sm">LOGOUT</button>
                                {/* <div className="profile-name">RM</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header> 
        </>
    )                                                   
}
export default Header