import React from "react"
import moment from "moment";
function HomeLoanAllLeads(props){
    return(
        <>
          <table className="table table-striped">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                    <th scope="col">Cust Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Mobile Number</th>
                                    <th scope="col">PAN Number</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Pincode</th>
                                    <th scope="col">Employment Type</th>
                                    <th scope="col">Loan Type</th>
                                    <th scope="col">Loan Amount Required</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                            props.homeloanleads.length > 0 ?
                            props.homeloanleads.map((value,key) => (
                                <tr>
                                <th scope="row">{key+1}</th>
                                <td>{value.lead_name}</td>
                                <td>{value.lead_email}</td>
                                <td>{value.lead_mobile} </td>
                                <td>{value.lead_pan_no}</td>
                                <td>{value.lead_city}</td>
                                <td>{value.lead_pincode}</td>
                                <td>{value.lead_emp_type}</td>
                                <td>{value.lead_loan_type}</td>
                                <td>{value.lead_amount}</td>
                                {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                            </tr>
                            )) :''}
                            </tbody>
                        </table>
        </>
    )
}
export default HomeLoanAllLeads