import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import moment from 'moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Pagination from "react-js-pagination";
let apiServices = new ApiService();
function SalesHeadLeads() {
    const didMountRef = useRef(true);
    const [LeadData, setLeadData] = useState([]);
    const [LeadPagination, setLeadPagination] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const tableRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getsalesheadleadsdata(1);
        }
        didMountRef.current = false;
    }, []);
    const getsalesheadleadsdata = (pageNumber) => {
        setSpinnerLoading(true)

        apiServices.getsalesheadleadsdataGetRequest(pageNumber).then(res => {
            if (res.data.status == 'success') {
                setLeadData(res.data.data.data);
                setLeadPagination(res.data.data);
                setSpinnerLoading(false);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
        })
    }
    const checkleadstatus = () => {
        const partnersessiondata = JSON.parse(localStorage.getItem('PARTNER_SESSION'));
        const dataString = {
            "user_type": "SALES_HEAD",
            "partner_id": partnersessiondata.partner_unique_id
            // "lead_id": leadId,
        }
        setSpinnerLoading(true);
        apiServices.credelioCheckLeadStatus(dataString).then(res => {
            // console.log(res);return false;
            if (res.data.status == 'success') {
                setSpinnerLoading(false);
                getsalesheadleadsdata(1);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
        })
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1>Sales Head Leads</h1>
                            <div className="breadcrumb-inner">
                                <ul className="edu-breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="separator">/</li>
                                    <li className="breadcrumb-item" onClick={goBack} style={{ cursor: "pointer" }}>Branch</li>
                                    <li className="separator">/</li>
                                    <li className="breadcrumb-item">Sales Head Leads</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row g-2">
                        <div className="col-lg-12">
                            <DownloadTableExcel
                                filename="LeadsData"
                                currentTableRef={tableRef.current}>
                                <button className="btn btn-primary">Export excel</button>
                            </DownloadTableExcel>
                            <button className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={checkleadstatus}> Check Lead Status </button>
                        </div>
                        <div className="col-lg-12">
                            <table className="table table-striped" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Cust. Name</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Creation Date</th>
                                        <th scope="col">Lender</th>
                                        <th scope="col">Bureau</th>
                                        <th scope="col">Agent Id</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Remark</th>
                                        {/* <th scope="col">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        LeadData.length > 0 ?
                                            LeadData.map((value, key) => (
                                                <tr>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{value.cl_name}</td>
                                                    <td>{value.cl_city}</td>
                                                    <td>{moment(value.created_at).format('DD-MM-YYYY')}</td>
                                                    <td>{value.cl_lenderSelected}</td>
                                                    <td>{value.cl_bureauprofile != '' ? value.cl_bureauprofile : 'NO DATA'}</td>
                                                    <td>{value.cl_agent_id}</td>
                                                    <td>{value.cl_status}</td>
                                                    <td>{value.cl_remarks}</td>
                                                    {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id,value.cl_agent_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                                                </tr>
                                            )) : ''
                                    }
                                </tbody>
                            </table>
                            <Pagination
                                activePage={LeadPagination.current_page}
                                totalItemsCount={LeadPagination.total}
                                itemsCountPerPage={LeadPagination.per_page}
                                onChange={(pageNumber) => getsalesheadleadsdata(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            // firstPageText="First"
                            // lastPageText="Last"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default SalesHeadLeads