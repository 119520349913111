import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Watch } from "react-loader-spinner";
function CreditCard() {
    const [allData, setAllData] = useState({ number: '', email: '', pan: '', name: '', pincode: '', employement: '', salary: '', confirm: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const handlechangedata = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        setAllData({ ...allData, [key]: value })
    }
    const submitlead = () => {
        const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (allData.number === '') {
            document.getElementById("number").style.border = "1px solid red";
            return false;
        } else if (allData.number.length > 10 || allData.number.length < 9) {
            document.getElementById("number").style.border = "1px solid red";
            return false;
        }
        else if (allData.email === '') {
            document.getElementById("email").style.border = "1px solid red";
            return false;
        } else if (!allData.email || regexEmail.test(allData.email) === false) {
            document.getElementById("email").style.border = "1px solid red";
            return false;
        }
        else if (allData.pan === '') {
            document.getElementById("pan").style.border = "1px solid red";
            return false;
        }
        else if (allData.name === '') {
            document.getElementById("name").style.border = "1px solid red";
            return false;
        }
        else if (allData.pincode === '') {
            document.getElementById("pincode").style.border = "1px solid red";
            return false;
        }
        else if (allData.employement === '') {
            document.getElementById("employement").style.border = "1px solid red";
            return false;
        }
        else if (allData.salary === '') {
            document.getElementById("salary").style.border = "1px solid red";
            return false;
        }
        else {
            setErrorMessage('')
        }
        let checkbox = document.getElementById("termscheckbox");
        if (!checkbox.checked) {
            checkbox.style.border = "1px solid red";
            return false;
        }
        else {
            setErrorMessage('');
        }
    }
    return (
        <>
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <h2>Credit Card</h2>
            <label>Mobile Number:</label>
            <input type="number" id="number" name="number" onChange={handlechangedata}></input><br />
            <label>Email:</label>
            <input type="email" id="email" name="email" onChange={handlechangedata}></input><br />
            <label>PAN:</label>
            <input type="text" id="pan" name="pan" onChange={handlechangedata}></input><br />
            <label>Name (as per PAN):</label>
            <input type="text" id="pan" name="name" onChange={handlechangedata}></input><br />
            <label>Pincode of current Residence:</label>
            <input type="text" id="pincode" name="pincode" onChange={handlechangedata}></input><br />
            <label>Employment Type</label>
            <select id="employement" name="employement" onChange={handlechangedata}>
                <option value="">Employement Type</option>
                <option value="0">Salaried</option>
                <option value="1">Self Employed</option>
                <option value="2">Self Employed Professional</option>
            </select><br />
            {allData.employement == "0"
                ? <><label>Net Monthly Salary Credit:</label>
                    <input type="text" id="salary" name="salary" onChange={handlechangedata}></input><br />
                </> : ""}
            <input type="checkbox" id="termscheckbox" ></input>
            <label>Confirm</label>
            <p>I confirm that Customer hasbeen informed about the Credit</p>
            <Link>Show</Link>
            {
                errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
            }
            <button type="button" onClick={submitlead}>Submit Lead</button>
        </>
    )
}
export default CreditCard