import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import { Watch } from "react-loader-spinner";
let apiServices = new ApiService();
function Login() {
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [forgotPasswordShowStatus, setForgotPasswordShowStatus] = useState(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [pageChange, setpageChange] = useState(2)
    const [userNumber, setUserNumber] = useState('')
    const [partnerId, setPartnerId] = useState('')
    const [OTPNumber, setOTPNumber] = useState('')
    const [LoginTo, setLoginTo] = useState(2)
    const [counter, setCounter] = React.useState(0);
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem('AUTH_TOKEN') != null && localStorage.getItem('AUTH_TOKEN') != undefined) {
                // const partnersessiondata = JSON.parse(localStorage.getItem('PARTNER_SESSION'));
                // if(partnersessiondata.partner_form_type == 1)
                // {
                //     navigate('/allagents');
                // }else{
                //     if(partnersessiondata.partner_sales_head == 1)
                //     {
                //     navigate('/allbranch');
                //     }else{
                //     navigate('/allleads');
                //     }
                // }
                navigate("/goto")
            } else {
                navigate("/")
            }
        }
        didMountRef.current = false;
    }, [])
    const clickonlogin = (e) => {
        e.preventDefault();
        if (userEmail === '') {
            document.getElementById("email").style.borderBottom = "1px solid red";
            return false;
        } else {
            document.getElementById("email").style.borderBottom = "";
            setErrorMessage('');
        }
        if (userPassword === '') {
            document.getElementById("password").style.borderBottom = "1px solid red";
            return false;
        } else {
            setErrorMessage('');
        }
        const dataString = {
            "partner_email": userEmail,
            "partner_password": userPassword
        }
        setSpinnerLoading(true);
        apiServices.partnerlogincheckPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                setSpinnerLoading(false);
                // navigate('/dashboard')
                localStorage.setItem('AUTH_TOKEN' , (res.data.auth_token));
                window.location.replace(res.data.dashboardurl)
            } else {
                setSpinnerLoading(false);
                setErrorMessage(res.data.message)
            }
        });
    }
    const showForgot = () => {
        setpageChange(pageChange + 1)
    }
    const showloginwithotp = () => {
        setpageChange(pageChange + 2)
    }
    const showotpnumber = () => {
        if (userNumber === '') {
            document.getElementById("number").style.borderBottom = "1px solid red";
            return false;
        }
        else {
            document.getElementById("number").style.borderBottom = "";
            setErrorMessage('');
        }
        if (userNumber.length !== 10) {
            setErrorMessage('Please Enter Your 10 Digit Mobile Number');
            return false;
        } else {
            setErrorMessage("");
        }
        setSpinnerLoading(true);
        const dataString = {
            "partner_mobile": userNumber
        }
        apiServices.partnerloginwithotpPostRequest(dataString).then(res => {
            console.log(res)
            if (res.data.status == "success") {
                setPartnerId(res.data.auth)
                setCounter(30);
                setSpinnerLoading(false);
                setpageChange(pageChange + 1)
            }
            else {
                setErrorMessage(res.data.message)
                setSpinnerLoading(false);
            }
        })
    }
    const verifyotp = () => {
        if (OTPNumber === '') {
            document.getElementById("otpnumber").style.borderBottom = "1px solid red";
            return false;
        }
        else {
            document.getElementById("otpnumber").style.borderBottom = "";
            setErrorMessage('');
        }
        setSpinnerLoading(true);
        const dataString = {
            "partner_otp": OTPNumber,
            "partner_id": partnerId
        }
        apiServices.partnerloginverifyotpPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                setSpinnerLoading(false);
                localStorage.setItem('AUTH_TOKEN', (res.data.auth_token));
                // if (LoginTo == 2) {
                    // localStorage.setItem('PRODUCT_URL', res.data.dashboardurl);
                    // if(res.data.type == 1)
                    // {
                    //     navigate('/allagents');
                    // }else{
                    //     if(res.data.saleshead == 1)
                    //     {
                    //     navigate('/allbranch');
                    //     }else{
                    //     navigate('/allleads');
                    //     }
                    // }
                    navigate('/goto')
                // } 
                // else {
                //     window.location.replace(res.data.dashboardurl)
                // }
            }
           
            else {
                setErrorMessage(res.data.message)
                setSpinnerLoading(false);
            }
        })
    }
    const resendotp = () => {
        setSpinnerLoading(true);
        const dataString = {
            "partner_mobile": userNumber
        }
        apiServices.partnerloginwithotpPostRequest(dataString).then(res => {
            console.log(res)
            if (res.data.status == "success") {
                setPartnerId(res.data.auth)
                setCounter(30);
                setSpinnerLoading(false);

            }
            else {
                setErrorMessage(res.data.message)
                setSpinnerLoading(false);
            }
        })
    }
    React.useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);
    return (
        <>
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-lg-5">
                        {
                            pageChange == 0 ?
                                <div className="login-form mt-5">

                                    <div className="text-center mb-5">
                                        <img src="/assests/img/logo.png" className="logo mb-3"></img>
                                        <h3 className="fw600">Goalstox Partner</h3>
                                        <p>Welcome back! Please login to continue.</p>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label>Email / Username</label>
                                        <input type="text" placeholder="Enter your email / username" id="email" onChange={(e) => setUserEmail(e.target.value)} value={userEmail}></input>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="d-flex justify-content-between">Password <a href="javascript:void(0);" onClick={showForgot}>Forgot Password?</a></label>
                                        <input type="password" placeholder="Enter your password" id="password" onChange={(e) => setUserPassword(e.target.value)} value={userPassword}></input>
                                    </div>
                                    {
                                        errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                                    }
                                    <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={clickonlogin}>Login</button>
                                    <h6 className="text-center mt-5" onClick={showloginwithotp}>  Login with OTP </h6>
                                </div>
                                : ""}
                        {pageChange == 1 ?
                            <div className="login-form mt-5">
                                <div className="text-center mb-5">
                                    <img src="/assests/img/logo.png" className="logo mb-3"></img>
                                    <h3 className="fw600">Reset Password</h3>
                                </div>
                                <div className="form-group mb-4">
                                    <label>Email / Username</label>
                                    <input type="text" placeholder="Enter your email / username" id="email" onChange={(e) => setUserEmail(e.target.value)} value={userEmail}></input>
                                </div>
                                {
                                    errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                                }
                                <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={clickonlogin}>Continue</button>

                            </div> : ""
                        }
                        {pageChange == 2 ?
                            <div className="login-form mt-5">
                                <div className="text-center mb-5">
                                    <img src="/assests/img/logo.png" className="logo mb-3"></img>
                                    <h3 className="fw600">Goalstox Partner</h3>
                                    <p>Login with OTP</p>
                                </div>

                                {/* <div className="form-group mb-4">
                              <select onChange={(e)=>setLoginTo(e.target.value)}>
                              <option value="1">PRODUCT</option>
                              <option value="2">DASHBOARD</option>
                              </select>
                            </div> */}
                                <div className="form-group mb-4">
                                    <input type="number" placeholder="Enter your 10 digit mobile number" id="number" onChange={(e) => setUserNumber(e.target.value)} value={userNumber}></input>
                                </div>
                                {
                                    errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                                }
                                <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={showotpnumber}>Continue</button>
                                <p className="text-center tx-12" style={{ marginTop: '15px' }}>By continuing I agree with the <a href="https://goalstox.com/partner-privacy-policy" target="new">Privacy Policy</a>, <a href="https://goalstox.com/partner-terms-and-conditions" target="new">Terms & Conditions</a></p>
                                <h6 className="text-center mg-t-20"><Link to="/register">Don't have account? Register</Link></h6>

                            </div> : ""
                        }
                        {pageChange == 3 ?
                            <div className="login-form mt-5">
                                <div className="text-center mb-5">
                                    <img src="/assests/img/logo.png" className="logo mb-3"></img>
                                    <h3 className="fw600">Verify Mobile Number</h3>
                                    <p>Otp has been sent to your registered mobile number</p>
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" placeholder="Enter your OTP" id="otpnumber" onChange={(e) => setOTPNumber(e.target.value)} value={OTPNumber} ></input>
                                    {counter == 0 ?
                                        <p id="timenew" className="tx-12 mg-t-5" style={{ marginTop: "5px", textAlign: "center" }} >Didn't receive OTP? Resend <Link onClick={resendotp}>(Click Here)</Link></p> :
                                        <p className="tx-12 mg-t-5" id="timenew" style={{ marginTop: "5px", textAlign: "center" }}>Resend OTP in {counter} seconds</p>}
                                </div>

                                {
                                    errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                                }
                                <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={verifyotp}>Continue</button>


                            </div> : ""
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;