import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Pagination from "react-js-pagination";
let apiServices = new ApiService();
function AllAgents() {
    const didMountRef = useRef(true);
    const tableRef = useRef(null);
    const [AgentsData, setAgentsData] = useState([]);
    const [AgentsPagination, setAgentsPagination] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [partnerSalesHead, setPartnerSalesHead] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [branchID, setbranchID] = useState('');
    const { branchid } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            console.log(localStorage.getItem('LEAD_SESSION'))
            const dataString = {
           
            }
            apiServices.getpartnerdata(dataString).then(res => {
                if(res.data.status == "success"){
                    setPartnerSalesHead(res.data.data.partner_sales_head)    
            }
                       
                else if(res.data.status == "error" && res.data.message == "Session expired"){
                    localStorage.removeItem('AUTH_TOKEN');
                    // alert(res.data.message)
                    setSpinnerLoading(false)
                    navigate("/")           
                }
                else{
                    setSpinnerLoading(false);
                    // setErrorMessage(res.data.message)
                }
            }); 
            
            if (branchid != null && branchid != undefined && branchid != '') {
                getbranchagentsdataPostRequest(atob(branchid), 1);
                setbranchID(atob(branchid));
            } else {
                getbranchagentsdataPostRequest('', 1);

            }
        }
        didMountRef.current = false;
    }, []);
    const getbranchagentsdataPostRequest = (branchid, pageNumber) => {
        const dataString = {
            "branch_id": branchid,
        }
        setSpinnerLoading(true);
        apiServices.getbranchagentsdataPostRequest(dataString, pageNumber).then(res => {
            if (res.data.status == 'success') {
                setAgentsData(res.data.data.data);
                setAgentsPagination(res.data.data);
                setSpinnerLoading(false);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1>Agents</h1>
                            <div className="breadcrumb-inner">
                                <ul className="edu-breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/goto">Dashboard</Link></li>
                                    <li className="separator">/</li>
                                    {partnerSalesHead == 1 ? <>
                                        <li className="breadcrumb-item" onClick={goBack} style={{ cursor: "pointer" }}>Branch</li>
                                        <li className="separator">/</li></>
                                        : ""}
                                    <li className="breadcrumb-item" >Agents</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    {/* <div className="row g-2">
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                            <h2>92</h2>
                            <h6>Lead inprogress</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                          <h2>41</h2>
                            <h6>Application Submitted</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                           <h2>21</h2>
                            <h6>AIP Success</h6>
                        </div>
                    </div>
                </div> */}
                    <DownloadTableExcel
                        filename="AgentsData"
                        // sheet="users"
                        currentTableRef={tableRef.current}>
                        <button className="btn btn-primary"> Export excel </button>
                    </DownloadTableExcel>
                    {branchid != null && branchid != undefined ?
                        <Link to={'/branchleads/' + branchid} className="btn btn-primary" style={{ marginLeft: "12px" }}> See All Leads </Link>
                        : <Link to='/branchleads' className="btn btn-primary" style={{ marginLeft: "12px" }}> See All Leads </Link>
                    }
                    <div className="row g-2">
                        <div className="col-lg-12">
                            <table className="table table-striped" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Agent ID</th>
                                        <th scope="col">Agent Name</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Email</th>
                                        {/* <th scope="col">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        AgentsData.length > 0 ?
                                            AgentsData.map((value, key) => (
                                                <tr>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{value.partner_unique_id}</td>
                                                    <td><Link to={'/allleads/' + btoa(value.partner_unique_id)}>{value.partner_name}</Link></td>
                                                    <td>{value.partner_mobile}</td>
                                                    <td>{value.partner_email}</td>
                                                    {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                                                </tr>
                                            )) : ''}
                                </tbody>
                            </table>
                            <Pagination
                                activePage={AgentsPagination.current_page}
                                totalItemsCount={AgentsPagination.total}
                                itemsCountPerPage={AgentsPagination.per_page}
                                onChange={(pageNumber) => getbranchagentsdataPostRequest(branchID, pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            // firstPageText="First"
                            // lastPageText="Last"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default AllAgents;