import React, { useState, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ApiService } from '../../Components/Services/apiservices';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Watch } from "react-loader-spinner";
let apiServices = new ApiService();
function Register() {
  const [errorMessage, setErrorMessage] = useState('')
  const [allData, setAllData] = useState({ candidatename: "", email: "", number: "", adhar: "", pan: "" })
  const [adharImage, setAdharImage] = useState({ preview: "", raw: "" })
  const [panImage, setPanImage] = useState({ preview: "", raw: "" })
  const [pageChange, setpageChange] = useState(0)
  const [OTPNumber, setOTPNumber] = useState('')
  const [successshow, setsuccessshow] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [successPopupMsg, setsuccessPopupMsg] = useState('');
  const [partnerId, setpartnerId] = useState(false)
  const adharRef = useRef(null);
  const panRef = useRef(null);
  const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
  const Navigate = useNavigate()
  const registerprocess = () => {
    if (allData.candidatename === '') {
      document.getElementById("name").style.borderBottom = "1px solid red";
      return false;
    }
    else {
      document.getElementById("name").style.borderBottom = "";
      setErrorMessage('');
    }
    if (allData.email === '') {
      document.getElementById("email").style.borderBottom = "1px solid red";
      return false;
    }
    else {
      document.getElementById("email").style.borderBottom = "";
      setErrorMessage('');
    }
    if (allData.number === '') {
      document.getElementById("mobile").style.borderBottom = "1px solid red";
      return false;
    }
    else {
      document.getElementById("mobile").style.borderBottom = "";
      setErrorMessage('');
    }
    if (allData.number.length != 10) {
      document.getElementById("mobile").style.borderBottom = "1px solid red";
      setErrorMessage('Invalid Mobile Number');
      return false;
    }
    else {
      document.getElementById("mobile").style.borderBottom = "";
      setErrorMessage('');
    }
    setSpinnerLoading(true);
    const formData = new FormData();
    formData.append('partner_name', allData.candidatename);
    formData.append('partner_email', allData.email);
    formData.append('partner_mobile', allData.number);
    formData.append('partner_aadhar', adharImage.raw);
    formData.append('partner_pan', panImage.raw);
    apiServices.partnerregisterPostRequest(formData).then(res => {
      if (res.data.status == 'success') {
        setSpinnerLoading(false);
        setpartnerId(res.data.auth);
        setpageChange(1);
      } else {
        setSpinnerLoading(false);
        setErrorMessage(res.data.message)
      }
    })
  }
  const handleUpload = async e => {
    e.current.click();
  };
  const handleAdharImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
      return;
    }
    if (e.target.files.length) {
      setAdharImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const handlePanImage = e => {
    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert("Invalid Image Format, supported formats are png | jpg | jpeg | webp.");
      return;
    }
    if (e.target.files.length) {
      setPanImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }
  };
  const handlechangedata = (e) => {
    const value = e.target.value;
    const key = e.target.name;
    setAllData({ ...allData, [key]: value });
  }
  const VerifyOTP = () => {
    if (OTPNumber === '') {
      document.getElementById("otpnumber").style.borderBottom = "1px solid red";
      return false;
    }
    else {
      document.getElementById("otpnumber").style.borderBottom = "";
      setErrorMessage('');
    }
    setSpinnerLoading(true);
    const dataString = {
      "partner_otp": OTPNumber,
      "partner_id": partnerId,
      "type": 'register'
    }
    apiServices.partnerloginverifyotpPostRequest(dataString).then(res => {
      if (res.data.status == "success") {
        setSpinnerLoading(false);
        setsuccessshow(true)
        setsuccessPopupMsg(res.data.message)
      }
      else {
        setErrorMessage(res.data.message)
        setSpinnerLoading(false);
      }
    })
  }
  const closepopup = () => {
    setsuccessshow(false)
    Navigate('/')
  }
  return (
    <>
      <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
        <div className='overlay-box'>
          <Watch color="#ffffff" visible={spinnerLoading} />
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-5">
            {pageChange == 0 ?
              <div className="login-form mt-5">
                <div className="text-center mb-5">
                  <img src="/assests/img/logo.png" className="logo mb-3"></img>
                  <h3 className="fw600">Goalstox Partner</h3>
                  <p>Register</p>
                </div>
                <div className="form-group mb-4">
                  <label>Name<span style={{ color: 'red' }}> *</span></label>
                  <input type="text" placeholder="Enter Your Name" id="name" name="candidatename" onChange={handlechangedata}></input>
                </div>
                <div className="form-group mb-4">
                  <label>Email<span style={{ color: 'red' }}> *</span></label>
                  <input type="email" placeholder="Enter Your Email" id="email" name="email" onChange={handlechangedata}></input>
                </div>
                <div className="form-group mb-4">
                  <label>Mobile Number<span style={{ color: 'red' }}> *</span></label>
                  <input type="number" placeholder="Enter Your Mobile Number" id="mobile" name="number" onChange={handlechangedata}></input>
                </div>
                <div className="form-group mb-4">
                  <label>Adhar Card</label>
                  <input type="file" placeholder="Adhar Card" id="adhar" name="adhar" ref={adharRef} onChange={handleAdharImage} onClick={handleUpload}></input>
                </div>
                <div className="form-group mb-4">
                  <label>Pan Card</label>
                  <input type="file" placeholder="Pan Card" id="pan" name="pan" onChange={handlePanImage} ref={panRef} onClick={handleUpload}></input>
                </div>
                {
                  errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                }
                <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={registerprocess}>Continue</button>
                <p className="text-center tx-12" style={{ marginTop: '15px' }}>By continuing I agree with the <a href="https://goalstox.com/partner-privacy-policy" target="new">Privacy Policy</a> and <a href="https://goalstox.com/partner-terms-and-conditions" target="new">Terms & Conditions</a></p>
                <p className="text-center mg-t-20"><Link to="/">Already have an account? Login</Link></p>
              </div> : ""}
            {pageChange == 1 ?
              <div className="login-form mt-5">
                <div className="text-center mb-5">
                  <img src="/assests/img/logo.png" className="logo mb-3"></img>
                  <h3 className="fw600">Verify Mobile Number</h3>
                  <p>Otp has been sent to your registered mobile number</p>
                </div>
                <div className="form-group mb-4">
                  <input type="text" placeholder="Enter your OTP" id="otpnumber" onChange={(e) => setOTPNumber(e.target.value)} value={OTPNumber}></input>
                </div>
                {
                  errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                }
                <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={VerifyOTP}>Submit</button>
              </div> : ""
            }
          </div>
        </div>
      </div>
      <SweetAlert success confirmBtnCssClass='alertpop ' onConfirm={closepopup} confirmBtnText='Great !' show={successshow}>
        <p> {successPopupMsg}</p>
      </SweetAlert>
    </>
  )
}
export default Register