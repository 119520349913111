import React from "react"
import moment from "moment";
function CreditCardAllLeads(props){
    return(
        <>
          <table className="table table-striped">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                    <th scope="col">Cust Name</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Creation Date</th>
                                    <th scope="col">Lender</th>
                                    <th scope="col">Bureau</th>
                                    <th scope="col">Agent Id</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                            props.creditcardleads.length > 0 ?
                            props.creditcardleads.map((value,key) => (
                                <tr>
                                <th scope="row">{key+1}</th>
                                <td>{value.cl_name}</td>
                                <td>{value.cl_city}</td>
                                <td>{moment(value.created_at).format('YYYY-MM-DD')} </td>
                                <td>{value.cl_lenderSelected}</td>
                                <td>{value.cl_bureauprofile!= null ?value.cl_bureauprofile :"NO DATA"}</td>
                                <td>{value.cl_agent_id}</td>
                                <td>{value.cl_status}</td>
                                <td>{value.cl_remarks}</td>
                                {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                            </tr>
                            )) :''}
                            </tbody>
                        </table>
        </>
    )
}
export default CreditCardAllLeads