import React from "react";
import moment from "moment";
function FixDepositBranchLeads(props) {
    return (
        <>
          <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cust Name</th>
                        <th scope="col">Email</th>
                        <th scope="col"> Mobile Number</th>
                        <th scope="col">Pan Number</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.fixdepositbranchleads.length > 0 ?
                            props.fixdepositbranchleads.map((value, key) => (
                                <tr>
                                    <th scope="row">{key + 1}</th>
                                    <td>{value.lead_name}</td>
                                    <td>{value.lead_email}</td>
                                    <td>{value.lead_mobile} </td>
                                    <td>{value.lead_pan_no}</td>
                                    {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                                </tr>
                            )) : ''}
                </tbody>
            </table>
        </>
    )
}
export default FixDepositBranchLeads