import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import SweetAlert from 'react-bootstrap-sweetalert';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Pagination from "react-js-pagination";
import moment from "moment";
import CreditCardAllLeads from "./CreditCardAllLeads";
import HomeLoanAllLeads from "./HomeLoanAllLeads";
import FixDepositeAllLeads from "./FixDepositAllLeads";
let apiServices = new ApiService();
function AllLeads() {
    const didMountRef = useRef(true);
    const { agentid } = useParams()
    const [LeadData, setLeadData] = useState([]);
    const [LeadPagination, setLeadPagination] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [successshow, setsuccessshow] = useState(false);
    const [LeadId, setLeadId] = useState('');
    const [AgentId, SetAgentId] = useState('');
    const [Status, setStatus] = useState('');
    const [SubStatus, setSubStatus] = useState('');
    const [UpdatedDate, setUpdatedDate] = useState('');
    const [Lender, setLender] = useState('');
    const [SelCard, setSelCard] = useState('');
    const [partnerSalesHead, setPartnerSalesHead] = useState("");
    const [partnerFormType, setPartnerFormType] = useState("");
    const [SelCompleteFromStatus, setCompleteFromStatus] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [leadSessionData, setLeadSessionData] = useState("");
    const tableRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            setLeadSessionData(localStorage.getItem('LEAD_SESSION'))
            const dataString = {

            }
            apiServices.getpartnerdata(dataString).then(res => {
                if(res.data.status == "success"){
                    setPartnerSalesHead(res.data.data.partner_sales_head)   
                    setPartnerFormType(res.data.data.partner_form_type)   
            }
              else if(res.data.status == "error" && res.data.message == "Session expired"){
                    localStorage.removeItem('AUTH_TOKEN');
                    // alert(res.data.message)
                    setSpinnerLoading(false)
                    navigate("/")           
                }
                else{
                    setSpinnerLoading(false);
                    // setErrorMessage(res.data.message)
                }
            }); 
            // const partnersessiondata = JSON.parse(localStorage.getItem('PARTNER_SESSION'));
            // setPartnerSalesHead(partnersessiondata.partner_sales_head)
            // setPartnerFormType(partnersessiondata.partner_form_type)
            if (agentid != null && agentid != undefined && agentid != '') {
                getcreditcardleadsdata(atob(agentid), 1);
                SetAgentId(atob(agentid))
            } else {
                getcreditcardleadsdata("", 1);
            }
        }
        didMountRef.current = false;
    }, []);  
    const getcreditcardleadsdata = (agentid, pageNumber) => {
        const leadsessiondata = localStorage.getItem('LEAD_SESSION')
        const dataString = {
            "partner_id": agentid,
            "lead_type": leadsessiondata
        }
        setSpinnerLoading(true);
        apiServices.getcreditcardleadsdata(dataString, pageNumber).then(res => {
            if (res.data.status == 'success') {
                setLeadData(res.data.data.data)
                setLeadPagination(res.data.data)
                setSpinnerLoading(false)
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    const resumeLeadCreationForm = (leadId) => {
        // setsuccessshow(true);return false;
        var partnerid = '';
        if (agentid != null && agentid != undefined && agentid != '') {
            partnerid = atob(agentid);
        } 
        const dataString = {
            "partner_id": partnerid,
            "lead_id": leadId,
        }
        setSpinnerLoading(true);
        apiServices.creditCardLeadResumeRedirection(dataString).then(res => {
            if (res.data.status == 'success') {
                setSpinnerLoading(false);
                setsuccessshow(false);
                window.location.replace(res.data.redirect_url)
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")
            } else {
                setSpinnerLoading(false);
                setsuccessshow(false);
            }
        })
    }
    const closepopup = () => {
        setsuccessshow(false);
        setLeadId('');
        setSubStatus('');
        setUpdatedDate('');
        setStatus('');
        setLender('');
        setSelCard('');
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1>Leads</h1>
                            <div className="breadcrumb-inner">
                                <ul className="edu-breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/goto">Dashboard</Link></li>
                                    <li className="separator">/</li>
                                    {partnerSalesHead == 1 ?
                                        <>
                                            <li className="breadcrumb-item" ><Link to="/allbranch">Branch</Link></li>
                                            <li className="separator">/</li>
                                        </> : ""
                                     }
                                    {(() => {
                                        if (partnerFormType == 1) {
                                            return (
                                                <>
                                                    <li className="breadcrumb-item" onClick={goBack} style={{ cursor: "pointer" }}>Agents</li>
                                                    <li className="separator">/</li>
                                                </>
                                            )
                                        }
                                        else {
                                            if (partnerSalesHead == 1) {
                                                return (
                                                    <>
                                                        <li className="breadcrumb-item" onClick={goBack} style={{ cursor: "pointer" }}>Agents</li>
                                                        <li className="separator">/</li>
                                                    </>)
                                            }
                                        }
                                    })()}
                                    <li className="breadcrumb-item">Leads</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    {/* <div className="row g-2">
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                            <h2>92</h2>
                            <h6>Lead inprogress</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                          <h2>41</h2>
                            <h6>Application Submitted</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                           <h2>21</h2>
                            <h6>AIP Success</h6>
                        </div>
                    </div>
                </div> */}
                    <div className="row g-2">
                        <div className="col-lg-12">
                            <DownloadTableExcel
                                filename="LeadsData"
                                currentTableRef={tableRef.current}
                            >
                                <button className="btn btn-primary"> Export excel </button>
                            </DownloadTableExcel>
                            {/* <button className="btn btn-primary" onClick={(e) => checkleadstatus()} style={{ marginLeft: '10px' }} > Check Lead Status </button> */}
                        </div>
                        <div className="col-lg-12">
                           {(() => {
                                if (leadSessionData == "CREDIT_CARD") {
                                    return (
                                        <CreditCardAllLeads creditcardleads={LeadData} />)
                                }
                                else if (leadSessionData == "HOME_LOAN") {
                                    return (
                                        <HomeLoanAllLeads homeloanleads={LeadData} />
                                    )
                                }
                                else if (leadSessionData == "FIX_DEPOSIT") {
                                    return (
                                        <FixDepositeAllLeads fixdepositleads={LeadData} />
                                    )
                                }
                            })()}
                            {LeadData != "" && LeadData != null ? 
                            <Pagination
                                activePage={LeadPagination.current_page}
                                totalItemsCount={LeadPagination.total}
                                itemsCountPerPage={LeadPagination.per_page}
                                onChange={(pageNumber) => getcreditcardleadsdata(AgentId, pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            />:""}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <SweetAlert confirmBtnCssClass='alertpop' onConfirm={closepopup} confirmBtnText='Great !' show={successshow}>
                <table className="table table-striped table-bordered mb-0">
                    <tbody>
                        <tr><td>Lead Id</td><td>{LeadId}</td></tr>
                        <tr><td>Status</td><td>{Status}</td></tr>
                        <tr><td>Sub Status</td><td>{SubStatus}</td></tr>
                        <tr><td>Last Updated Date</td><td>{UpdatedDate}</td></tr>
                        <tr><td>Selected Lender</td><td>{Lender}</td></tr>
                        <tr><td>Card Selected</td><td>{SelCard}</td></tr>
                        {
                            SelCompleteFromStatus == true ?
                                <tr>
                                    <td>Application Pending</td>
                                    <td><button onClick={(e) => resumeLeadCreationForm(LeadId)} className="btn btn-primary btn-sm">Complete Application</button></td>
                                </tr> : null
                        }
                    </tbody>
                </table>
            </SweetAlert>
        </>
    )
}
export default AllLeads;