import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
let apiServices = new ApiService();
function Dashboard() {
    const didMountRef = useRef(true);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [LeadInProgress, setLeadInProgress] = useState();
    const [ApplicationSubmitted, setApplicationSubmitted] = useState();
    const [AipApproved, setAipApproved] = useState();
    const [ProductsIssued, setProductsIssued] = useState();
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem('AUTH_TOKEN') != null && localStorage.getItem('AUTH_TOKEN') != undefined) {
            } else {
                navigate('/')
            }
            credelioCreditCardDashboard();
        }
        didMountRef.current = false;
    }, []);
    const creditCardLogin = (type) => {
        const dataString = {
            "request_type": type,
        }
        setSpinnerLoading(true);
        apiServices.creditCardLoginPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                // localStorage.setItem('CREDILO_BEARER_TOKEN', res.data.bearertoken);
                setSpinnerLoading(false);
                window.location.replace(res.data.redirect_url)
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            } else {
                setSpinnerLoading(false);
                setErrorMessage(res.data.message)
            }
        });
    }
    const credelioCreditCardDashboard = () => {
        const dataString = {
        }
        setSpinnerLoading(true);
        apiServices.credelioCreditCardDashboardPostRequest(dataString).then(res => {
            if (res.data.credit_card) {
                setLeadInProgress(res.data.credit_card.leads_in_progress)
                setApplicationSubmitted(res.data.credit_card.application_submitted)
                setAipApproved(res.data.credit_card.aip_approved)
                setProductsIssued(res.data.credit_card.products_issued)
                setSpinnerLoading(false);
                // window.location.replace(res.data.redirect_url)
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
            else {
                setSpinnerLoading(false);
                setErrorMessage(res.data.message)
            }
        });
    }
    const gotoallleads = () => {
        navigate('/allleads');
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row g-2">
                        <div className="col-lg-2" onClick={(e) => creditCardLogin('credit-card-lead-creation')}>
                            <div className="golicon mb-3">
                                <img src="/assests/img/credit.png"></img>
                                <h6>Credit Card Lead Creation</h6>
                            </div>
                        </div>
                        <div className="col-lg-2" onClick={(e) => creditCardLogin('credit-card-library')}>
                            <div className="golicon mb-3">
                                <img src="/assests/img/credit.png"></img>
                                <h6>Credit Card Library</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row g-2">
                        <div className="col-lg-3 col-6" onClick={gotoallleads}>
                            <div className="golicon-second mb-2">
                                <h6>Leads In Progress</h6>
                                <h6 className='mb-0'>{LeadInProgress}</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6" onClick={gotoallleads}>
                            <div className="golicon-second mb-2">
                                <h6>Application Submitted</h6>
                                <h6 className='mb-0'>{ApplicationSubmitted}</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6" onClick={gotoallleads}>
                            <div className="golicon-second col-6">
                                <h6>Product Issued</h6>
                                <h6 className='mb-0'>{ProductsIssued}</h6>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6" onClick={gotoallleads}>
                            <div className="golicon-second">
                                <h6>AIP Approved</h6>
                                <h6 className='mb-0'>{AipApproved}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Dashboard;