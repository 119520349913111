import React, { useRef, useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import moment from 'moment';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Pagination from "react-js-pagination";
import CreditBranchLeads from "./CreditCardBranchLeads";
import HomeLoanBranchLeads from "./HomeLoanBranchLeads";
import FixDepositBranchLeads from "./FixDepositBranchLeads";
let apiServices = new ApiService()
function BranchLeads() {
    const didMountRef = useRef(true);
    const { branchid } = useParams()
    const [LeadData, setLeadData] = useState([]);
    const [LeadPagination, setLeadPagination] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [leadSessionData, setLeadSessionData] = useState("")
    const tableRef = useRef(null);
    const navigate = useNavigate()
    useEffect(() => {
        console.log(branchid);
        if (didMountRef.current) {
            console.log(localStorage.getItem('LEAD_SESSION'))
            setLeadSessionData(localStorage.getItem('LEAD_SESSION'))
            getbranchleadsdata(1);
            // getbranchleadsdata(partnersessiondata.partner_unique_id,1);
        }
        didMountRef.current = false;
    }, []);
    const getbranchleadsdata = (pageNumber) => {
        setSpinnerLoading(true);
        var BranchID = '';
        var Branchtype = '';
        if (branchid != null && branchid != undefined) {
            BranchID = atob(branchid)
            Branchtype = 'ID';
        }
        const leadsessiondata = localStorage.getItem('LEAD_SESSION')
        const dataString = {
            "branch_id": BranchID,
            "branch_type": Branchtype,
            "lead_type": leadsessiondata
        }
        apiServices.getbranchleadsdataPostRequest(dataString, pageNumber).then(res => {
            if (res.data.status == 'success') {
                setLeadData(res.data.data.data);
                setLeadPagination(res.data.data);
                setSpinnerLoading(false);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    const checkleadstatus = () => {
        var BranchID = '';
        var Branchtype = '';
        if (branchid != null && branchid != undefined) {
            BranchID = atob(branchid)
            Branchtype = 'ID';
        }
        const dataString = {
            "user_type": "BRANCH_HEAD",
            "partner_id": BranchID,
            "branch_type": Branchtype,
        }
        setSpinnerLoading(true);
        apiServices.credelioCheckLeadStatus(dataString).then(res => {
            // console.log(res);return false;
            if (res.data.status == 'success') {
                setSpinnerLoading(false);
                getbranchleadsdata(1);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    const goBack = () => {
        navigate(-1);
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1>Brand Leads</h1>
                            <div className="breadcrumb-inner">
                                <ul className="edu-breadcrumb">
                                    <li className="breadcrumb-item">Home</li>
                                    <li className="separator">/</li>
                                    <li className="breadcrumb-item" onClick={goBack} style={{ cursor: "pointer" }}>Agents</li>
                                    <li className="separator">/</li>
                                    <li className="breadcrumb-item">Brand Leads</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row g-2">
                        <div className="col-lg-12">
                            <DownloadTableExcel
                                filename="LeadsData"
                                currentTableRef={tableRef.current}>
                                <button className="btn btn-primary"> Export excel </button>
                            </DownloadTableExcel>
                            <button className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={checkleadstatus}> Check Lead Status </button>
                        </div>
                        <div className="col-lg-12">
                            {(() => {
                                if (leadSessionData == "CREDIT_CARD") {
                                    return (
                                        <CreditBranchLeads creditbranchleads={LeadData} />)
                                }
                                else if (leadSessionData == "HOME_LOAN") {
                                    return (
                                        <HomeLoanBranchLeads homeloanbranchleads={LeadData} />
                                    )
                                }
                                else if (leadSessionData == "FIX_DEPOSIT") {
                                    return (
                                        <FixDepositBranchLeads fixdepositbranchleads={LeadData} />
                                    )
                                }
                            })()}
                            <Pagination
                                activePage={LeadPagination.current_page}
                                totalItemsCount={LeadPagination.total}
                                itemsCountPerPage={LeadPagination.per_page}
                                onChange={(pageNumber) => getbranchleadsdata(pageNumber)}
                                itemClass="page-item"
                                linkClass="page-link"
                            // firstPageText="First"
                            // lastPageText="Last"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default BranchLeads