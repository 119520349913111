import logo from './logo.svg';
import './App.css';
import {Outlet, Navigate } from "react-router-dom"

import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Containers/Login';
import Dashboard from './Containers/Dashboard';
import CreditCard from './Containers/CreditCard';
import AllLeads from './Containers/AllLeads';
import Register from './Containers/Register';
import AllAgents from './Containers/AllAgents';
import AllBranch from './Containers/AllBranch';
import { isLogin } from './middleware/auth';
import SalesHeadLeads from './Containers/SalesHeadLeads';
import BranchLeads from './Containers/BranchLeads';
import Goto from './Containers/Goto';
import FixDepositDetail from './Containers/FixDepositDetail';

const PrivateWrapper = () => {
  return isLogin() ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  return (
    <div className="App">
       <Router>
        <Routes>
          <Route exact path='/' activeClassName= 'active' element={<Login/>}/>
          <Route exact path = '/register' activeClassName='active' element={<Register/>}/>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/dashboard' activeClassName='active' element={<Dashboard/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/creditcard' activeClassName='active' element={<CreditCard/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/allleads' activeClassName='active' element={<AllLeads/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/allleads/:agentid' activeClassName='active' element={<AllLeads/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/allagents' activeClassName='active' element={<AllAgents/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/allagents/:branchid' activeClassName='active' element={<AllAgents/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/allbranch' activeClassName='active' element={<AllBranch/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/salesheadleads' activeClassName='active' element={<SalesHeadLeads/>}/>
          </Route>
          <Route element={<PrivateWrapper auth={{ isLogin: false }} />}>
          <Route exact path = '/fixeddeposidetail' activeClassName='active' element={<FixDepositDetail/>}/>
          <Route exact path = '/branchleads' activeClassName='active' element={<BranchLeads/>}/>
          <Route exact path = '/branchleads/:branchid' activeClassName='active' element={<BranchLeads/>}/>
          <Route exact path = '/goto' activeClassName='active' element={<Goto/>}/>
         
          </Route>
        </Routes>
       </Router>
    </div>
  );
}

export default App;
