import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom"
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import { ApiService } from '../../Components/Services/apiservices';
import Modal from 'react-bootstrap/Modal';
let apiServices = new ApiService();
function Goto() {
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [saveBankLoanLeadData, setBankLoanLeadData] = useState({ candidatename: '', email: '', number: '', pan: '', city: '', pincode: '', employement_type: '', loan_type: '', loan_amount: '' })
    const [saveFixDepositData, setFixDepositData] = useState({ candidatename: '', email: '', number: '', pan: '' })
    const [alertFormId, setAlertFormId] = useState("")
    const [adminData, setAdminData] = useState("")
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.getthemeGetRequest().then(res => {
                if (res.data.status == "success") {
                    setAdminData(res.data.data)
                }
            })
        }
        didMountRef.current = false;
    }, [])
    const creditCardLogin = (type) => {
        const dataString = {
            "request_type": type,
        }
        setSpinnerLoading(true);
        apiServices.creditCardLoginPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                // localStorage.setItem('CREDILO_BEARER_TOKEN', res.data.bearertoken);
                setSpinnerLoading(false);
                window.location.replace(res.data.redirect_url)
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")
            }
            else {
                setSpinnerLoading(false);
                setErrorMessage(res.data.message)
            }
        });
    }
    const dashboardcreditcard = (sessionvalue) => {
        const dataString = {
        }
        setSpinnerLoading(true);
        apiServices.getpartnerdata(dataString).then(res => {
            if (res.data.status == "success") {
                if (res.data.data.partner_form_type == 1) {
                    navigate('/allagents');
                }
                else {
                    if (res.data.data.partner_sales_head == 1) {
                        navigate('/allbranch');
                    } else {
                        navigate('/allleads');
                    }
                }
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")
            }
            else {
                setSpinnerLoading(false);
                // setErrorMessage(res.data.message)
            }
        });
        localStorage.setItem('LEAD_SESSION', sessionvalue);
        // const partnersessiondata = JSON.parse(localStorage.getItem('PARTNER_SESSION'));
        // if (partnersessiondata.partner_form_type == 1) {
        //     navigate('/allagents');
        // }
        // else {
        //     if (partnersessiondata.partner_sales_head == 1) {
        //         navigate('/allbranch');
        //     } else {
        //         navigate('/allleads');
        //     }
        // }
    }
    const openbankloanformalert = () => {
        setShow(true)
    }
    const closebankloanformalert = () => {
        setShow(false)
    }
    const fixeddepositalertopen = () => {
        navigate("/fixeddeposidetail")
    }
    const handlechangedata = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        setBankLoanLeadData({ ...saveBankLoanLeadData, [key]: value });
    }
    const submitbankloandata = () => {
        if (saveBankLoanLeadData.candidatename == "") {
            document.getElementById("name").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.email == "") {
            document.getElementById("email").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.number == "") {
            document.getElementById("mobile").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.number.length != 10) {
            document.getElementById("mobile").style.border = "1px solid red"
            setErrorMessage('Invalid Mobile Number')
            return false
        }
        else {
            setErrorMessage("")
        }
        if (saveBankLoanLeadData.pan == "") {
            document.getElementById("pan").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.pan.length != 10) {
            document.getElementById("pan").style.border = "1px solid red";
            setErrorMessage('Invalid Pan Number');
            return false
        }
        else {
            setErrorMessage("")
        }
        if (saveBankLoanLeadData.city == "") {
            document.getElementById("city").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.pincode == "") {
            document.getElementById("pincode").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.employement_type == "") {
            document.getElementById("employement_type").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.loan_type == "") {
            document.getElementById("loan_type").style.border = "1px solid red"
            return false
        }
        if (saveBankLoanLeadData.loan_amount == "") {
            document.getElementById("loan_amount").style.border = "1px solid red"
            return false
        }
        const dataString = {
            "type": "homeloan",
            "candidate_name": saveBankLoanLeadData.candidatename,
            "candidate_email": saveBankLoanLeadData.email,
            "mobile_number": saveBankLoanLeadData.number,
            "pancard_number": saveBankLoanLeadData.pan,
            "city": saveBankLoanLeadData.city,
            "pincode": saveBankLoanLeadData.pincode,
            "employement_type": saveBankLoanLeadData.employement_type,
            "loan_type": saveBankLoanLeadData.loan_type,
            "loan_amount": saveBankLoanLeadData.loan_amount,
        }
        apiServices.productleadprocessPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                if(adminData.gs_homeloan_url != ""){
                window.open(adminData.gs_homeloan_url)
                }
                else{
                    alert(res.data.message)
                }
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="home-tabs">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button className="nav-link active" id="nav-dashboard-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard" type="button" role="tab" aria-controls="nav-dashboard" aria-selected="true">Dashboard</button>
                                        <button className="nav-link" id="nav-product-tab" data-bs-toggle="tab" data-bs-target="#nav-product" type="button" role="tab" aria-controls="nav-product" aria-selected="false">Products</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="htbox" onClick={(e) => dashboardcreditcard('CREDIT_CARD')}>
                                                    <div className="htbox-icon"><img src="/assests/img/credit.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Credit Card</h6>
                                                        <p className="mb-0">Offer best credit cards from top banks to your customers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="htbox" onClick={(e) => dashboardcreditcard('HOME_LOAN')}>
                                                    <div className="htbox-icon"><img src="/assests/img/homeloan.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Home Loan</h6>
                                                        <p className="mb-0">Submit home loan lead and we will do the rest process for you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="htbox" onClick={(e) => dashboardcreditcard('FIX_DEPOSIT')}>
                                                    <div className="htbox-icon"><img src="/assests/img/fixeddeposit.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Fixed Deposit</h6>
                                                        <p className="mb-0">Offer high quality corporate fixed deposits to your clients.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-product" role="tabpanel" aria-labelledby="nav-product-tab">
                                        <div className="row">
                                            <div className="col-lg-4"  >
                                                <div className="htbox" onClick={(e) => creditCardLogin('credit-card-lead-creation')}>
                                                    <div className="htbox-icon"><img src="/assests/img/credit.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Credit Card</h6>
                                                        <p className="mb-0">Offer best credit cards from top banks to your customers.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4" onClick={openbankloanformalert}>
                                                <div className="htbox" >
                                                    <div className="htbox-icon"><img src="/assests/img/homeloan.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Home Loan</h6>
                                                        <p className="mb-0">Submit home loan lead and we will do the rest process for you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4" onClick={fixeddepositalertopen} >
                                                <div className="htbox" >
                                                    <div className="htbox-icon"><img src="/assests/img/fixeddeposit.png"></img></div>
                                                    <div className="htbox-content">
                                                        <h6>Fixed Deposit</h6>
                                                        <p className="mb-0">Offer high quality corporate fixed deposits to your clients.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Modal show={show} onHide={closebankloanformalert} size="lg">
                <Modal.Body  >
                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closebankloanformalert}></button> */}
                    <div className="login-form">
                        <div className="text-center">
                            <h3 className="fw600">Home Loan Lead</h3>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Name<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder="Enter Your Name" id="name" name="candidatename" onChange={handlechangedata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Email<span style={{ color: 'red' }}> *</span></label>
                            <input type="email" placeholder="Enter Your Email" id="email" name="email" onChange={handlechangedata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Mobile Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="number" placeholder="Enter Your Mobile Number" id="mobile" name="number" onChange={handlechangedata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>PAN Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder="Enter Your PAN Number" id="pan" name="pan" onChange={handlechangedata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Current City of residence<span style={{ color: 'red' }}> *</span></label>
                            <select onChange={handlechangedata} name="city" id="city">
                                <option>Select</option>
                                <option value="Mumbai">Mumbai</option>
                                <option value="Navi Mumbai">Navi Mumbai</option>
                                <option value="Thane">Thane</option>
                                <option value="Bengaluru/Bangalore">Bengaluru/Bangalore</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Current Residence City Pincode <span style={{ color: 'red' }}> *</span></label>
                            <input type="number" placeholder="Enter Your Current Residence City Pincode" id="pincode" name="pincode" onChange={handlechangedata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Employment Type<span style={{ color: 'red' }}> *</span></label>
                            <select onChange={handlechangedata} name="employement_type" id="employement_type">
                                <option>Select</option>
                                <option value="Salaried">Salaried</option>
                                <option value="Self Employed">Self Employed</option>
                                <option value="Self Employed Profssional">Self Employed Profssional</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Loan type<span style={{ color: 'red' }}> *</span></label>
                            <select onChange={handlechangedata} name="loan_type" id="loan_type">
                                <option>Select</option>
                                <option value="New Home Loan">New Home Loan</option>
                                <option value="Home Loan - Balance Transfer">Home Loan - Balance Transfer</option>
                                <option value="New Loan Against Property">New Loan Against Property</option>
                                <option value="Loan Against Property - Balance Transfer"> Loan Against Property - Balance Transfer</option>
                            </select>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Loan Amount Required<span style={{ color: 'red' }}> *</span></label>
                            <input type="number" placeholder="Enter Your Loan Amount" id="loan_amount" name="loan_amount" onChange={handlechangedata}></input>
                        </div>
                        {
                            errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                        }
                        <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={submitbankloandata}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Goto