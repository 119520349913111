import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom"
import { ApiService } from '../../Components/Services/apiservices';
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { Watch } from "react-loader-spinner";
import SweetAlert from 'react-bootstrap-sweetalert';
let apiServices = new ApiService();
function AllBranch() {
    const didMountRef = useRef(true);
    const [BranchData, setBranchData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            console.log(localStorage.getItem('LEAD_SESSION'))
            getbranchData();
        }
        didMountRef.current = false;
    }, []);
    const getbranchData = () => {
        setSpinnerLoading(true);
        apiServices.getbranchdataPostRequest().then(res => {
            if (res.data.status == 'success') {
                setBranchData(res.data.data);
                setSpinnerLoading(false);
            }
            else if (res.data.status == "error" && res.data.message == "Session expired") {
                localStorage.removeItem('AUTH_TOKEN');
                // alert(res.data.message)
                setSpinnerLoading(false)
                navigate("/login")
            }
            else {
                alert(res.data.message)
                setSpinnerLoading(false)
            }
        })
    }
    return (
        <>
            <Header />
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <Watch color="#ffffff" visible={spinnerLoading} />
                </div>
            </div>
            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-12'>
                            <h1>Branch</h1>
                            <div className="breadcrumb-inner">
                                <ul className="edu-breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/goto">Dashboard</Link></li>
                                    <li className="separator">/</li>
                                    <li className="breadcrumb-item">Branch</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    {/* <div className="row g-2">
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                            <h2>92</h2>
                            <h6>Lead inprogress</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                          <h2>41</h2>
                            <h6>Application Submitted</h6>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="golicon mb-3">
                           <h2>21</h2>
                            <h6>AIP Success</h6>
                        </div>
                    </div>
                </div> */}
                    <Link to="/salesheadleads" className="btn btn-primary btn-sm">See All Leads</Link>
                    <div className="row g-2">
                        <div className="col-lg-12">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Branch ID</th>
                                        <th scope="col">Branch Name</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Email</th>
                                        {/* <th scope="col">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        BranchData.length > 0 ?
                                            BranchData.map((value, key) => (
                                                <tr>
                                                    <th scope="row">{key + 1}</th>
                                                    <td>{value.partner_unique_id}</td>
                                                    <td><Link to={'/allagents/' + btoa(value.partner_id)}>{value.partner_branch_name}</Link></td>
                                                    <td>{value.partner_mobile}</td>
                                                    <td>{value.partner_email}</td>
                                                    {/* <td><button onClick={(e) => checkleadstatus(value.cl_lead_id)} className="btn btn-primary btn-sm">Check Status</button></td> */}
                                                </tr>
                                            )) : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default AllBranch;