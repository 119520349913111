import constant from './constant';
import axios from 'axios';
const partnerlogincheckUrl = "partnerlogincheck"
const creditCardLoginUrl = "credelio-credit-card-login"
const credelioCreditCardDashboardUrl = "credelioCreditCardDashboard"
const getcreditcardleadsdataUrl = "getcreditcardleadsdata"
const credelioCheckLeadStatusUrl = "credelioCheckLeadStatus"
const getpartnerdataUrl = "getpartnerdata"
const creditCardLeadResumeRedirectionUrl = "creditCardLeadResumeRedirection"
const partnerloginwithotpUrl = "partnerloginwithotp";
const partnerloginverifyotpUrl = "partnerloginverifyotp"
const partnerregisterUrl = "registerpartner"
const getbranchagentsdataUrl = "getbranchagentsdata"
const getbranchdataUrl = "getbranchdata";
const getsalesheadleadsdataUrl = "getsalesheadleadsdata";
const getbranchleadsdataUrl = "getbranchleadsdata";
const productleadprocessUrl = "product-lead-process";
const getthemeUrl = "gettheme";
const fixeddepositUrl = "fixed-deposit";
const partnerlogoutUrl = "partnerlogout"

function getauthtoken(){
  let token = localStorage.getItem('AUTH_TOKEN');
  let Authtoken = '';
  if(token !=null && token !='' && token !=undefined){
      Authtoken = token;
  }
  const config = {
    headers: { 'X-Authorization': `Bearer ${Authtoken}` }
  };
  return config;
  }

const client = axios.create({
    baseURL: constant.API_URL,
  });
  export class ApiService {
    partnerlogincheckPostRequest(request) {
      return client.post(partnerlogincheckUrl, request)
    }
    creditCardLoginPostRequest(request) {
      return client.post(creditCardLoginUrl, request , getauthtoken())
    }
    credelioCreditCardDashboardPostRequest(request) {
      return client.post(credelioCreditCardDashboardUrl, request , getauthtoken())
    }
    getcreditcardleadsdata(request,pageNumber) {
      return client.post(getcreditcardleadsdataUrl+'?page='+pageNumber, request ,getauthtoken() )
    }
    credelioCheckLeadStatus(request) {
      return client.post(credelioCheckLeadStatusUrl, request ,getauthtoken())
    }
    getpartnerdata(request) {
      return client.post(getpartnerdataUrl, request, getauthtoken())
    }
    creditCardLeadResumeRedirection(request) {
      return client.post(creditCardLeadResumeRedirectionUrl, request ,getauthtoken())
    }
    partnerloginwithotpPostRequest(request) {
      return client.post(partnerloginwithotpUrl, request)
    }
    partnerloginverifyotpPostRequest(request) {
      return client.post(partnerloginverifyotpUrl, request)
    }
    partnerregisterPostRequest(request) {
      return client.post(partnerregisterUrl, request)
    }
    getbranchagentsdataPostRequest(request,pageNumber) {
      return client.post(getbranchagentsdataUrl+'?page='+pageNumber, request,getauthtoken())
    }
    getbranchdataPostRequest(){
      return client.get(getbranchdataUrl ,getauthtoken())
    }
    getsalesheadleadsdataGetRequest(pageNumber){
      return client.get(getsalesheadleadsdataUrl+'?page='+pageNumber ,getauthtoken())
    }
    
    getbranchleadsdataPostRequest(request,pageNumber) {
      return client.post(getbranchleadsdataUrl+'?page='+pageNumber , request,getauthtoken())
    }
    productleadprocessPostRequest(request) {
      return client.post(productleadprocessUrl, request , getauthtoken())
    }
    getthemeGetRequest(){
      return client.get(getthemeUrl)
    }

    fixeddepositGetRequest(){
      return client.get(fixeddepositUrl , getauthtoken())
    }
    partnerlogoutPostRequest(request) {
      return client.post(partnerlogoutUrl, request , getauthtoken())
    }
  }
  