import React, { useEffect, useRef, useState } from "react"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from '../../Components/Services/apiservices';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
let apiServices = new ApiService();
function FixDepositDetail() {
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    const [fixDepositData, setFixDepositData] = useState([])
    const [saveFixDepositData, setSaveFixDepositData] = useState({ candidatename: '', email: '', number: '', pan: '' })
    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState("")
    const [show1, setShow1] = useState(false)
    const [formType, setFormType] = useState("")
    const [link, setLink] = useState("")
    const [spinnerLoading, setSpinnerLoading] = useState()
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.fixeddepositGetRequest().then(res => {
                if (res.data.status == "success") {
                    setFixDepositData(res.data.fdData)
                }
                else if (res.data.status == "error" && res.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    // alert(res.data.message)
                    setSpinnerLoading(false)
                    navigate("/login")
                }
                else {
                    alert(res.data.message)
                    setSpinnerLoading(false)
                }
            })
        }
        didMountRef.current = false;
    }, []);
    const fixeddepositalertclose = () => {
        setShow1(false)
    }
    const handlechangefixdepositdata = (e) => {
        const value = e.target.value;
        const key = e.target.name;
        setSaveFixDepositData({ ...saveFixDepositData, [key]: value });
    }
    const submitfixeddeposit = () => {
        if (saveFixDepositData.candidatename == "") {
            document.getElementById("name").style.border = "1px solid red"
            return false
        }
        if (saveFixDepositData.email == "") {
            document.getElementById("email").style.border = "1px solid red"
            return false
        }
        if (saveFixDepositData.number == "") {
            document.getElementById("mobile").style.border = "1px solid red"
            return false
        }
        if (saveFixDepositData.number.length != 10) {
            document.getElementById("mobile").style.border = "1px solid red";
            setErrorMessage('Invalid Mobile Number');
            return false
        }
        if (saveFixDepositData.pan == "") {
            document.getElementById("pan").style.border = "1px solid red"
            return false
        }
        if (saveFixDepositData.pan.length != 10) {
            document.getElementById("pan").style.border = "1px solid red";
            setErrorMessage('Invalid Pan Number');
            return false
        }
        else {
            setErrorMessage("")
        }
        const dataString = {
            "type": formType,
            "candidate_name": saveFixDepositData.candidatename,
            "candidate_email": saveFixDepositData.email,
            "mobile_number": saveFixDepositData.number,
            "pancard_number": saveFixDepositData.pan
        }
        apiServices.productleadprocessPostRequest(dataString).then(res => {
            if (res.data.status == "success") {
                if (formType == "fillform") {
                    if (link != null) {
                        window.open(link)
                        window.location.reload()
                    }
                    else{
                    alert(res.data.message)
                    window.location.reload()
                    }
                }
                else if (formType == "sendlink") {
                    setSuccessMessage(res.data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
                else if (res.data.status == "error" && res.data.message == "Session expired") {
                    localStorage.removeItem('AUTH_TOKEN');
                    // alert(res.data.message)
                    setSpinnerLoading(false)
                    navigate("/login")
                }
                else {
                    alert(res.data.message)
                    setSpinnerLoading(false)
                }
            }
        })
    }
    const openfixdepositemodel = (type, link) => {
        setLink(link)
        setShow1(true)
        setFormType(type)
    }
    return (
        <>
            <Header />
            <div className="container">
                <div className="row">
                    {fixDepositData.map((value) => (
                        <div className="col-lg-4">
                            <div className="bg-white position-relative">
                                <h3>{value.fd_title}</h3>
                                <p className="text-color">{moment(value.created_at).format('DD-MMM-YYYY')}</p>
                                <table className="table">
                                    <tbody>
                                        <tr className="font-color">
                                            <td>Rating</td>
                                            <td>Min. Investment</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{value.fd_rating}</strong></td>
                                            <td><strong>{value.fd_min_investment}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row">
                                    {value.rate_duration.map((subvalue) => (
                                        <div className="col-3">
                                            <p className="mb-0">{subvalue.rd_duration}M</p>
                                            <p><strong>{subvalue.rd_rate}%</strong></p>
                                        </div>))}
                                </div>
                                <div>
                                      
                                        <p className="text-color" dangerouslySetInnerHTML={{ __html: value.fd_message != null ? value.fd_message :"&nbsp"}}></p> 
                                </div>
                                <div className="d-flex form-fill-btn" style={{left:"32%"}}>
                                    <button className="btn btn-outline-danger" style={{backgroundColor:"#fff" , color:"#dc3545"}}>COMING SOON</button>
                                    {/* <button onClick={(e) => openfixdepositemodel("fillform", value.fd_link)} className="btn btn-outline-danger me-3" style={{backgroundColor:"#fff" , color:"#dc3545"}}>Fill form</button>
                                    <button onClick={(e) => openfixdepositemodel("sendlink", value.fd_link)} className="btn btn-outline-danger" style={{backgroundColor:"#fff" , color:"#dc3545"}}>Send link</button> */}
                                </div>
                            </div>
                        </div>))}
                </div>
            </div>
            <Footer />
            <Modal show={show1} onHide={fixeddepositalertclose} size="lg">
                <Modal.Body  >
                    <div className="login-form">
                        <div className="text-center">
                            <h3 className="fw600">Fixed Deposit Lead</h3>
                        </div>
                        {successMessage != "" ?
                            <div className="alert alert-info">
                                <strong>{successMessage}</strong>
                            </div> : ""}
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Name<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder="Enter Your Name" id="name" name="candidatename" onChange={handlechangefixdepositdata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Email<span style={{ color: 'red' }}> *</span></label>
                            <input type="email" placeholder="Enter Your Email" id="email" name="email" onChange={handlechangefixdepositdata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>Mobile Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="number" placeholder="Enter Your Mobile Number" id="mobile" name="number" onChange={handlechangefixdepositdata}></input>
                        </div>
                        <div className="form-group mb-4">
                            <label style={{ textAlign: "initial" }}>PAN Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" placeholder="Enter Your PAN Number" id="pan" name="pan" onChange={handlechangefixdepositdata}></input>
                        </div>
                        {
                            errorMessage !== '' ? <p className="text text-danger">{errorMessage}</p> : null
                        }
                        <button type="button" className="btn btn-primary btn-lg btn-full text-uppercase" onClick={submitfixeddeposit}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default FixDepositDetail